// function select2() {
//     //Bootstrap Duallistbox
//     // $('.duallistbox').bootstrapDualListbox({
//     //     moveOnSelect: false,
//     //     filterPlaceHolder: 'Filtrar..',
//     //     nonSelectedListLabel: 'Disponible',
//     //     selectedListLabel: 'Seleccionados',
//     //     moveSelectedLabel: 'Mover elemento',
//     //     removeSelectedLabel: 'Remover elemento',
//     //     preserveSelectionOnMove: 'moved',
//     //     moveAllLabel: 'Mover Todos',
//     //     removeAllLabel: 'Remover Todos',
//     //     infoText: false
//     // });

//     //Initialize Select2 Elements
//     // $('.select2bs4').select2({
//     //     theme: 'bootstrap4',
//     //     allowClear: true,
//     //     "language": {
//     //         "noResults": function() { return 'Sin resultados.'; },
//     //         "searching": function() { return "Buscando.."; }
//     //     }
//     // });

//     //Realiza focus a la busqueda
//     // $('#fempleado').on('select2:open', () => {
//     //     document.querySelector('.select2-search__field').focus();
//     // });

//     //Get data
//     // $('#fempleado').on('select2:select', function(e) {
//     //     var data = e.params.data;
//     //     console.log(data);
//     // });


//     //Resetea modal
//     // $('.modal').on('hidden.bs.modal', function() {
//     //     $(this).find('form')[0].reset(); //Resetea form
//     //     $('.select2bs4').val("").trigger("change"); //Resetea select2
//     // });
// }

// function selected() {
//     return $('#fempleado')
// }